exports.components = {
  "component---themes-qab-free-cover-src-pages-404-tsx": () => import("./../../../../../themes/qab-free-cover/src/pages/404.tsx" /* webpackChunkName: "component---themes-qab-free-cover-src-pages-404-tsx" */),
  "component---themes-qab-free-cover-src-pages-confirmation-tsx": () => import("./../../../../../themes/qab-free-cover/src/pages/confirmation.tsx" /* webpackChunkName: "component---themes-qab-free-cover-src-pages-confirmation-tsx" */),
  "component---themes-qab-free-cover-src-pages-customer-details-tsx": () => import("./../../../../../themes/qab-free-cover/src/pages/customer-details.tsx" /* webpackChunkName: "component---themes-qab-free-cover-src-pages-customer-details-tsx" */),
  "component---themes-qab-free-cover-src-pages-details-tsx": () => import("./../../../../../themes/qab-free-cover/src/pages/details.tsx" /* webpackChunkName: "component---themes-qab-free-cover-src-pages-details-tsx" */),
  "component---themes-qab-free-cover-src-pages-index-tsx": () => import("./../../../../../themes/qab-free-cover/src/pages/index.tsx" /* webpackChunkName: "component---themes-qab-free-cover-src-pages-index-tsx" */),
  "component---themes-qab-free-cover-src-pages-review-tsx": () => import("./../../../../../themes/qab-free-cover/src/pages/review.tsx" /* webpackChunkName: "component---themes-qab-free-cover-src-pages-review-tsx" */),
  "component---themes-qab-free-cover-src-pages-your-details-tsx": () => import("./../../../../../themes/qab-free-cover/src/pages/your-details.tsx" /* webpackChunkName: "component---themes-qab-free-cover-src-pages-your-details-tsx" */)
}

