export type FormSteps =
  | 'species'
  | 'petDetails'
  | 'petHistory'
  | 'customerDetails'
  | 'customerDeclarations'
  | 'declaration'
  | 'review'
  | 'confirmation';

export type FormPages =
  | 'species'
  | 'details'
  | 'customer-details'
  | 'cover'
  | 'review'
  | 'confirmation';

export const indexPage: FormPages = 'species';
export const indexStep: FormSteps = 'species';

export const topLevelSteps = [
  'species',
  'petDetails',
  'activityLevel',
  'customerDetails',
  'paymentMethod',
  'review',
  'confirmation',
];

export const stepToPageMap: Record<FormSteps, FormPages> = {
  species: 'species',
  petDetails: 'details',
  petHistory: 'details',
  customerDetails: 'customer-details',
  customerDeclarations: 'customer-details',
  declaration: 'cover',
  review: 'review',
  confirmation: 'confirmation',
};
